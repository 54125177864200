import { Button, HStack, Image, Spacer, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSettings, useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { UserImage } from "../atoms/UserImage";
import { MyNotifications } from "./MyNotifications";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { SideMenu } from "../../pages/Home/components/SideMenu";

export function TopMenu() {
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const { logo, defaultBackgroundColor, menuColor } = useTheme();
    const { isApp, isIOS } = useSettings();
    const { logged } = useProfile();
    const { primaryColor } = useTheme();

    if (!isApp) return <></>;

    return (
        <VStack
            spacing={4}
            padding="0px 5%"
            paddingTop={"10px"}
            paddingBottom={"10px"}
            className="layered-box-light"
            top="0px"
            width="100%"            
            display="flex"
            justify="center"
            alignItems="center"
            backgroundColor={menuColor || defaultBackgroundColor || "#FFFFFF"}


            position="fixed"
            zIndex="999"
        >
            <SideMenu
                isOpen={isOpenMenu}
                onClose={() => setIsOpenMenu(false)}
            />
            <HStack width="100%">
                <VStack>
                    <Image
                        maxW="140px"
                        maxHeight={'60px'}
                        src={logo}
                        objectFit="contain"
                    />
                </VStack>
                <Spacer />
                {logged ? (
                    <HStack spacing={5}>
                        <VStack spacing={0}>
                            <MyNotifications />

                        </VStack>
                        <Link to="/perfil">
                            <UserImage size={50} />
                        </Link>
                    </HStack>
                ) : (
                    <Button onClick={() => setIsOpenMenu(true)} backgroundColor={"transparent"}>
                        <FontAwesomeIcon icon={faBars} size="xl" color={primaryColor} />
                    </Button>
                )}
            </HStack>
        </VStack>
    );
}
